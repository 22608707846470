@import "src/styles/variables";

.icon {

}

.navItem {
  padding: 16px 12px 16px 16px;
  gap: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'CodecPro200', sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 18px;
  color: rgba(35, 35, 35, 1);

  &:hover {
    border-bottom-width: 1px;
    border-bottom-color: rgba(35, 35, 35, 1);
    border-bottom-style: solid;
    margin-bottom: -1px;
  }
}

.navSubmenuItem {
  padding: 16px 12px 16px 16px;
  gap: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(35, 35, 35, 1);
  font-size: 16px;
  font-weight: 300;
  font-family: 'CodecPro300', sans-serif;
  line-height: 24px;
  letter-spacing: -0.01em;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background: #EFEFE7;
    cursor: pointer;
  }
}

.navSubmenuLinkItem {
  padding: 16px 12px 16px 16px;
  gap: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: rgba(35, 35, 35, 1);
  font-size: 16px;
  font-weight: 300;
  font-family: 'CodecPro300', sans-serif;
  line-height: 24px;
  letter-spacing: -0.01em;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background: #EFEFE7;
    cursor: pointer;
  }
}

.subtitle {
  font-family: 'CodecPro300', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.6px;
  text-align: left;
  color: #8C8C8C;
}

.title {
  font-family: 'CodecPro300', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #232323;
}

.cottagesContainer {
  display: flex;
}
