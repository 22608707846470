.footerStyle {
  background-color: var(--gray-100);

  .footerP {
    font-size: 16px;
    font-weight: 300;
    font-family: "CodecPro300", sans-serif;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #8c8c8c;
  }
}

.footerH2 {
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}

.footerForm {
  display: flex;
  background-color: var(--gray-100);
  padding: 80px 56px;
  justify-content: space-between;
  flex-direction: column;
  gap: 50px;

  .footerP {
    font-size: 16px;
    font-weight: 300;
    font-family: "CodecPro300", sans-serif;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #8c8c8c;
  }
}

.contentStyle {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  width: 320px;
  margin: 0 auto;
  gap: 20px;
}

.rights {
  border-top: 1px solid #2d2f33;
  width: 100%;
  height: 72px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: 300;
    font-family: "CodecPro300", sans-serif;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #8c8c8c;
  }
}

.menuList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menuItem {
  font-size: 16px;
  font-weight: 300;
  font-family: "CodecPro300", sans-serif;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #fafafa;
}

.dataWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .h3P {
    font-size: 24px;
    font-weight: 200;
    line-height: 33.6px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--secondary-100);
    margin-bottom: 0;
    font-family: "CodecPro200", sans-serif;
  }

  span {
    color: var(--primary-80);
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0%;
    font-family: "CodecPro300", sans-serif;
  }
}

@media screen and (min-width: 768px) {
  .dataWrapper {
    gap: 24px;
    width: 65%;
  }

  .rights {
    width: 90%;
  }

  .footerStyle {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .footerForm {
    flex-direction: row;
  }

  .contentStyle {
    flex-direction: row;
    width: 768px;
  }

  .dataWrapper {
    display: flex;
    align-items: baseline;
  }
}

@media screen and (min-width: 1280px) {
  .rights {
    width: 90%;
  }

  .footerForm {
    flex-direction: row;
  }

  .footerStyle {

    .contentStyle {
      width: 1280px;
      padding: 56px 56px;

      .dataWrapper {
        display: flex;
        width: 50%;
      }
    }
  }
}
