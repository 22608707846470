@import "src/styles/variables";

.mobileNav {
  display: block;
}

@media (min-width: 768px) {
  .desktopNav {
    display: flex;
    list-style: none;
    gap: 20px;
  }
}

.burgerIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mobileMenu {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #F2F3EC;
  z-index: 100;
  padding: 20px;
}

.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;

  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: var(--gray-100);
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0;
  gap: 8px;

  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: var(--gray-100);
}

.border {
  box-sizing: border-box;
  border-top: 0.5px solid var(--primary-40);
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.cottageItem {
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;

  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-100);

  &__gray {
    font-weight: 200;
    font-size: 12px;
    color: var(--gray-70);
  }
}
