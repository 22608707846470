@import "src/styles/variables";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 40px 24px 24px;
  width: 400px;
  position: relative;
  text-align: center;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  font-family: $fCodecProR;
  box-sizing: border-box;
}

.close-modal-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
}

.close-modal-btn:hover {
  color: #000;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 36px;
}

.modal-logo {
  width: 72px;
  height: 44px;
  margin-bottom: 16px;
}

.modal-header__title {
  margin: 0;
  padding-bottom: 4px;
  font-family: 'CodecPro300', sans-serif;
  font-size: 16px;
  color: var(--Gray-900, #101828);
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.modal-header__sub {
  margin: 0;
  color: var(--Gray-70, #8C8C8C);
  font-size: 14px;
  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  line-height: 140%;
}

.modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modal-form label {
  margin-bottom: 4px;
  width: 100%;
  font-family: 'CodecPro300', sans-serif;
  color: var(--Gray-80, #696969);
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
}

.input-name {
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 24px;
  font-family: 'CodecPro300', sans-serif;
  border: 1px solid var(--Gray-40, #E3E3E3);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.16px;
  outline: none;
  box-sizing: border-box;
  color: var(--Gray-100, #232323);
}

.input-name::placeholder {
  color: var(--Gray-70, #8C8C8C);
}

.input-name:focus,
.input-name:hover {
  border: 1px solid var(--Primary-60, #A4A892);
  box-shadow: 0px 0px 0px 4px #F2F3EF;
}

.input-name.error {
  border: 1px solid var(--Error-300, #FDA29B);
}

.error {
  border: 1px solid var(--Error-300, #FDA29B);
}

.input-phone {
  width: 100%;
  height: 98px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.phone-input-footer .PhoneInput {
  width: 100%;
}

.PhoneInput {
  width: 100%;
  position: relative;
}

.PhoneInput input {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.16px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid var(--Gray-40, #E3E3E3);
  color: var(--Gray-100, #232323);
}

.PhoneInput input::placeholder {
  color: var(--Gray-70, #8C8C8C);
}

.PhoneInput input:focus,
.PhoneInput input:hover {
  border: 1px solid var(--Primary-60, #A4A892);
  box-shadow: 0px 0px 0px 4px #F2F3EF;
}

.PhoneInputCountry {
  margin-right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  border: 1px solid var(--Gray-40, #E3E3E3);
  border-right: 0;
  padding: 8px 12px;
  gap: 4px;
}

.phone-input-footer .PhoneInputCountry {
  margin-right: 0;
  top: 0;
  transform: translateY(0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  border: 1px solid var(--Gray-40, #E3E3E3);
  border-right: 0;
  padding: 8px 12px;
  gap: 4px;
}

.PhoneInputCountryIcon {
  box-shadow: none;
  width: 20px;
}

.PhoneInputCountryIcon--border {
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none;
}


.PhoneInputCountrySelectArrow {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin-top: -5px;
}


.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: black;
}


.error-message {
  display: flex;
  align-items: start;
  margin-top: 6px;
  color: var(--Error-500, #F04438);
  font-size: 14px;
  font-weight: 300;
  font-family: 'CodecPro300', sans-serif;
  line-height: 140%;
  position: absolute;
  top: 62px;
  left: 0;
}

.submit-btn {
  color: var(--Gray-0, #FFF);
  padding: 12px;
  border: 1px solid var(--Primary-100, #3F4134);
  width: 100%;
  cursor: pointer;
  background: var(--Primary-100, #3F4134);
  font-size: 16px;
  font-weight: 300;
  font-family: 'CodecPro300', sans-serif;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.submit-btn:hover {
  border: 1px solid var(--Primary-80, #6C705A);
  background: var(--Primary-80, #6C705A);
}

/* Mobile Styles */
@media (max-width: 500px) {
  .modal-content {
    width: 290px;
  }

  .modal-header__title {
    font-size: 12px;
  }

  .modal-header__sub {
    font-size: 10px;
  }

  .input-name,
  .PhoneInput input,
  .submit-btn {
    font-size: 12px;
  }

  label {
    font-size: 12px;
  }

  .error-message {
    font-size: 12px;
  }
}

@media (min-width: 2048px) {
  .modal-content {
    width: 620px;
  }

  .close-modal-btn {

    img {
      width: 36px;
      height: 36px;
    }
  }

  .modal-header {
    padding-bottom: 46px;
  }

  .modal-logo {
    width: 120px;
    height: 75px;
    margin-bottom: 26px;
  }

  .modal-header__title {
    margin: 0;
    padding-bottom: 14px;
    font-size: 30px;
  }

  .modal-header__sub {
    font-size: 22px;
  }

  .modal-form label {
    margin-bottom: 4px;
    font-size: 22px;
  }

  .input-name {
    margin-bottom: 24px;
    font-size: 26px;
  }

  .input-phone {
    margin-top: 20px;
  }

  .PhoneInput input {
    font-size: 26px;
  }

  .error-message {
    margin-top: 6px;
    font-size: 20px;
    position: absolute;
    top: 88px;
    left: 0;
  }

  .submit-btn {
    padding: 12px;
    font-size: 26px;
    margin-top: 36px;
  }
}
