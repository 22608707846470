@import "src/styles/variables";

.container {
  height: 62px;
  width: 100%;
  background: $background;
  display: flex;
  flex-direction: row;
   justify-content: space-between;
   align-items: center;
  position: fixed;
  padding: 16px 20px 16px 20px;
  gap: 0;
  z-index: 900;
}

.mainNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkNav {
  display: flex;

}

.langContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}

.ganplanContainer {
  height: 62px;
  width: 100%;
  background: $background;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 16px 20px 16px 20px;
  gap: 0;
  z-index: 900;
}

.menuItem {
  height: 50px;

  &:hover {
    border-width: 0 0 1px 0;
    border-color: black;
  }
}

.rightPart {
  display: flex;
  align-items: center;
}

.rightPartMobile {
  flex-direction: row-reverse;
  gap: 24px;
}

.lngWrapper {
  display: flex;
  gap: 12px;
}

.navItem {
  padding: 4px 0 1px 0;
  gap: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'CodecPro300', sans-serif;
  font-size: 14px;
  line-height: 19.6px;
  color: #8C8C8C;

  &:hover {
    border-bottom-width: 1px;
    border-bottom-color: rgba(35, 35, 35, 1);
    border-bottom-style: solid;
    margin-bottom: -1px;
  }
}

.active {
  border-bottom-width: 1px;
  border-bottom-color: rgba(35, 35, 35, 1);
  border-bottom-style: solid;
  margin-bottom: -1px;
  color: rgba(35, 35, 35, 1);
}

@media screen and (min-width: 1280px) {
  .navItem {
    padding: 16px 12px 16px 12px;
    gap: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'CodecPro200', sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: 18px;
    color: #8C8C8C;

    &:hover {
      border-bottom-width: 1px;
      border-bottom-color: rgba(35, 35, 35, 1);
      border-bottom-style: solid;
      margin-bottom: -1px;
    }
  }

  .container {
    height: 62px;
    padding: 16px 56px 16px 56px;
    gap: 0;
    border-width: 0 0 1px 0;
  }

  .ganplanContainer {
    height: 62px;
    padding: 16px 56px 16px 56px;
    gap: 0;
    border-width: 0 0 1px 0;
  }
}
