.footerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.footerForm label {
  margin-bottom: 4px;
  width: 100%;
  font-family: "CodecPro300", sans-serif;
  color: var(--Gray-80, #696969);
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
}

.inputName {
  background-color: #ffffff;
  width: 100%;
  padding: 8px 12px;
  font-family: "CodecPro300", sans-serif;
  border: 1px solid var(--Gray-40, #e3e3e3);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.16px;
  outline: none;
  box-sizing: border-box;
  color: var(--Gray-100, #232323);
}

.inputName::placeholder {
  color: var(--Gray-70, #8c8c8c);
}

.inputName:focus,
.inputName:hover {
  border: 1px solid var(--Primary-60, #a4a892);
  box-shadow: 0px 0px 0px 4px #f2f3ef;
}

.inputName.error {
  border: 1px solid var(--Error-300, #fda29b);
}

.error {
  border: 1px solid var(--Error-300, #fda29b);
}

.inputPhone {
  width: 100%;
  height: 98px;
  display: flex;
  flex-direction: column;
}

.PhoneInput {
  width: 100%;
  background-color: #ffffff;
}

.PhoneInput input {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-family: "CodecPro300", sans-serif;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.16px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid var(--Gray-40, #e3e3e3);
  color: var(--Gray-100, #232323);
}

.PhoneInput input::placeholder {
  color: var(--Gray-70, #8c8c8c);
}

.PhoneInput input:focus,
.PhoneInput input:hover {
  border: 1px solid var(--Primary-60, #a4a892);
  box-shadow: 0px 0px 0px 4px #f2f3ef;
}

.PhoneInputCountry {
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  border: 1px solid var(--Gray-40, #e3e3e3);
  border-right: 0;
  padding: 8px 12px;
  gap: 4px;
}

.PhoneInputCountryIcon {
  box-shadow: none;
  width: 20px;
}

.PhoneInputCountryIcon--border {
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none;
}

.PhoneInputCountrySelectArrow {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin-top: -5px;
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: black;
}

.errorMessage {
  display: flex;
  align-items: start;
  margin-top: 6px;
  color: var(--Error-500, #f04438);
  font-size: 14px;
  font-weight: 300;
  font-family: "CodecPro300", sans-serif;
  line-height: 140%;
  top: 62px;
  left: 0;
}

.submitBtn {
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  font-size: 12px;
  font-weight: 300;
  font-family: "CodecPro300", sans-serif;
  line-height: 16.8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.submitBtn:hover {
  background: var(--Primary-80, #6c705a);
}

.submitBtn:active {
    background: #D8DAD0;
}

@media screen and (min-width: 1260px) {
  .submitBtn {
    width: 100%;
    padding: 12px 20px 12px 20px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
}