$background: #F2F3EC;
$gray80: #8F8F8F;
$gray100: #222222;
$primary100: #3F4134;
$white60: #FFFFFF99;
$background2: #EDEEE5;

$fMatterR: 'MatterRegular', sans-serif;
$fMatterM: 'MatterMedium', sans-serif;
$fCodecProR: 'CodecPro-Regular', sans-serif;
$fCodecProL: 'CodecPro-Light', sans-serif;
$fCodecProT: 'CodecPro-Thin', sans-serif;
$fCodecProU: 'CodecPro-Ultra', sans-serif;
$fCodecProH: 'CodecPro-Heavy';

:root {
  --primary-100: #3f4134;
  --primary-90: #4e5141;
  --primary-80: #6c705a;
  --primary-70: #8a8f73;
  --primary-60: #a4a892;
  --primary-50: #bec1b1;
  --primary-40: #d8dad0;
  --primary-30: #efefe7;
  --primary-20: #f2f3ec;
  --secondary-100: #a3a068;
  --secondary-20: #dadac8;
  --secondary-10: #e7e7da;
  --gray-100: #232323;
  --gray-80: #696969;
  --gray-70: #8c8c8c;
  --gray-60: #afafaf;
  --gray-50: #d2d2d2;
  --gray-40: #e3e3e3;
  --gray-30: #f5f5f5;
  --gray-20: #fafafa;
  --gray-0: #fff;
  --additional-colors-a1: #0c42ff;
  --additional-colors-a2: #fd9800;
  --additional-colors-a3: #f3e07f;
  --opacity-decreased-distance-and-time: rgba(138, 143, 115, 0.4);
  --opacity-decreased-location: rgba(193, 255, 196, 0.05);
  --opacity-decreased-button: rgba(255, 255, 255, 0.7);
  --opacity-decreased-panel: rgba(242, 243, 236, 0.6);
  --dark-gray: #222;
}
